// eslint-disable-next-line no-unused-vars
const style = require('./src/components/css/themes.css');

exports.onClientEntry = () => {
  if (
    !window.location.pathname.includes('/flight/') &&
    !window.location.pathname.includes('/flight_dev/') &&
    !window.location.pathname.includes('/flight_feedback/') &&
    !window.location.pathname.includes('/airport_report/') &&
    !window.location.pathname.includes('/flight_report/') &&
    !window.location.pathname.includes('/app_report/')
  )
    return;
  const langs = [`es`, `de`, `fr`, `ja`, `ko`, `ru`];
  const userLang = navigator.language.substr(0, 2);
  let detectedLang;
  let isLangLink = false;
  let linkLang = '';

  langs.forEach((lang) => {
    if (userLang === lang) {
      detectedLang = lang;
    }
    if (window.location.pathname.startsWith(`/${lang}/`)) {
      isLangLink = true;
      linkLang = lang;
    }
  });

  let path;
  if (isLangLink) {
    if (detectedLang) {
      path = window.location.pathname.replace(linkLang, detectedLang);
    } else {
      window.location.pathname = window.location.pathname.replace(linkLang + '/', '');
    }
  } else {
    path = `/${detectedLang}${window.location.pathname}`;
  }

  if (detectedLang && detectedLang !== linkLang) window.location.pathname = path;
};
